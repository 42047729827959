<template>
  <div class="foundation_page">
    <div class="banner_content">
      <div class="nar_content">
        <h2>JCI FOUNDATION</h2>
        <p>More than 50 years ago, the JCI Senate was established to recognize the outstanding achievements and service
          of JCI members worldwide.</p>
      </div>
      <topNavBar />
    </div>
    <div class="recognizes">
      <img src="../../assets/pc_img/mobile_banner.png" style="
                    object-fit: inherit;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: 0;
                  " alt="" />
      <div class="content">
        <h3>Invest in JCI through the JCI Foundation.</h3>
        <p>More than 50 years ago, the JCI Foundation was established to empower JCI members and friends to financially
          invest in the future of JCI. Since it began, the JCI Foundation has raised more than US $8 million to support
          specific efforts contributing to growth and development, leadership development, training and Local or
          National Organization extension. The JCI Foundation is a registered 501(c)3 charitable organization in the
          United States.</p>
        <div class="rec_btn">
          <div class="btn_com" @click="toDetail(1)">VIEW THE JCI FOUNDATION STAKEHOLDERS</div>
          <div class="btn_com" @click="toDetail(2)">VIEW THE JCI FOUNDATION DONOR LEVELS</div>
        </div>
      </div>
    </div>
    <div class="tomorrow">
      <div class="content">
        <h4>Invest in tomorrow</h4>
        <p>Donors who support JCI through monetary contributions to the JCI Foundation invest in the future of the
          organization.</p>
        <div class="tom_btn">
          <div class="btn_com" @click="toDetail">INVEST</div>
        </div>
      </div>
    </div>
    <div class="remembering">
      <div class="title">
        <h3>Why invest in the JCI Foundation?</h3>
      </div>
      <p class="detail">Contributing to this fund gives you the opportunity to actively support JCI organizations and
        initiatives around the world. The JCI Foundation empowers JCI members to turn their goals into a reality while
        creating sustainable change.Your contributions will support initiatives such as leadership training,
        membership recruitment, infrastructure, local and national events and skills development.</p>
      <div class="content">
        <div class="item">
          <div class="img_box"><img src="../../assets/pc_img/impact.png" alt=""></div>
          <span>Magnify impact</span>
          <p>The JCI Foundation magnifies the impact of each donation by accruing interest that is then used to fund
            initiatives that build a better future.</p>
        </div>
        <div class="item">
          <div class="img_box"><img src="../../assets/pc_img/leaders.png" alt=""></div>
          <span>Build new skills & leaders</span>
          <p>JCI members never stop learning. The JCI Foundation financially supports programs that build capacity and
            create new skills for future leaders of JCI.</p>
        </div>
        <div class="item">
          <div class="img_box"><img src="../../assets/pc_img/future.png" alt=""></div>
          <span>Create a strong future</span>
          <p>The JCI Foundation offers development grants to JCI’s nearly 120 National Organizations to support and
            empower local members to increase their impact.</p>
        </div>
      </div>
    </div>
    <div class="change">
      <div class="content">
        <h4>Take action to create positive change.</h4>
        <div class="inform">
          <img src="../../assets/pc_img/contribution.png" alt="">
          <span>Make a contribution</span>
          <p>A contribution to the JCI Foundation is an investment in the future. JCI members who donate US $100 or more
            to the JCI Foundation are recognized with one of several levels. Your donations add up over time, so little
            by little, you can achieve the highest level. Help sustain JCI's legacy by contributing today.</p>
          <div class="cha_btn">
            <div class="btn_com" @click="toDetail">INVEST</div>
          </div>
        </div>
      </div>
    </div>
    <div class="donate">
      <div class="content">
        <span>Donate</span>
        <p>Support our impact! Donations fund numerous national and international projects contributing to training,
          development and sustainable, positive change around the world.</p>
        <div class="don_btn">
          <div class="btn_com" @click="toDetail">DONATE</div>
        </div>
      </div>
    </div>
    <pageFooter />
  </div>
</template>

<script>
  import pageFooter from "./components/Contact";
  import topNavBar from "./components/TopBar";

  export default {
    components: {
      topNavBar,
      pageFooter
    },
    methods: {
      toDetail(value) {
        if (value == 1) {
          window.open('https://jci.cc/web/#/foundation?type=1')
        } else if (value == 2) {
          window.open('https://jci.cc/web/#/foundation?type=2')
        } else {
          window.open('https://jci.cc/web/#/foundation-form')
        }
      },
    },
  };
</script>

<style scoped lang='less'>
  .foundation_page {
    .banner_content {
      position: relative;
      height: 5.14rem;
      background-image: url('../../assets/pc_img/jci_banner.png');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 88%;
    }

    .nar_content {
      position: absolute;
      color: #fff;
      top: 60%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: 6.8rem;

      h2 {
        font-size: .6rem;
      }

      p {
        font-size: .2rem;
        margin-top: .3rem;
        font-weight: 300;
      }
    }

    .btn_com {
      width: 2.4rem;
      height: .77rem;
      line-height: .77rem;
      background: #4266b0;
      border-radius: .39rem;
      margin-bottom: .2rem;
      text-align: center;
      color: #ffffff;
      font-size: .22rem;
    }

    h3 {
      font-size: .32rem;
      font-weight: 700;
      border-bottom: .04rem solid #EDBE38;
      width: 5.8rem;
      padding-bottom: .3rem;
      padding-left: .46rem;
      text-align: right;
    }

    .recognizes {
      position: relative;
      background-color: #F4F4F4;

      .content {
        padding-top: 1.2rem;
      }

      p {
        font-size: .3rem;
        padding: .6rem 1rem .68rem;
        text-align: left;
        line-height: .48rem;
      }

      .rec_btn {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 1rem;
        padding-bottom: .88rem;
        z-index: 1;

        .btn_com {
          width: 100%;
        }
      }
    }

    .remembering {
      .title {

        h3 {
          text-align: right;
          padding-left: 1rem;
          margin-top: 1rem;
        }
      }

      .detail {
        padding: 0 1rem;
        font-size: .3rem;
        text-align: left;
        line-height: .48rem;
        margin-top: .6rem;
      }

      .content {
        padding: 0 1rem .9rem;

        .item {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: .6rem;

          p {
            font-size: .28rem;
            text-align: left;
            margin-bottom: .1rem;
            margin-top: .1rem;
          }

        }

        img {
          width: 1.9rem;
          /* height: 1.5rem; */
          margin-bottom: .2rem;
        }

        span {
          font-size: .26rem;
          color: #0D0F30;
          font-weight: 700;
        }
      }
    }

    .tomorrow {
      height: 6.8rem;
      background: #4266b0;
      padding: 1.2rem 1rem 1.5rem;
      color: #fff;
      text-align: left;

      h4 {
        font-size: .32rem;
        font-weight: 700;
        margin-bottom: .4rem;
      }

      p {
        font-size: .3rem;
        line-height: .48rem;
      }

      .tom_btn {
        display: flex;
        justify-content: center;
        margin-top: .8rem;

        div {
          background-color: #fff;
          color: #4266B0;
        }
      }
    }

    .change {
      background: #f4f4f4;
      padding: .8rem .3rem 1rem;

      h4 {
        font-size: .32rem;
        font-weight: 700;
      }

      .inform {
        background-color: #fff;
        margin-top: .4rem;
        padding: .6rem .9rem .5rem;
        display: flex;
        flex-direction: column;
        text-align: left;
        align-items: center;

        img {
          width: .68rem;
        }

        span {
          font-size: .32rem;
          font-weight: 700;
          margin-top: .6rem;
        }

        p {
          font-size: .3rem;
          line-height: .48rem;
          margin-bottom: .8rem;
        }
      }
    }

    .donate {
      background-image: url('../../assets/pc_img/donate.png');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 80%;
      height: 6.8rem;

      .content {
        display: flex;
        height: 100%;
        color: #fff;
        padding: 0 1rem;
        flex-direction: column;
        text-align: left;
        align-items: center;
        justify-content: center;
      }

      span {
        font-size: .32rem;
        font-weight: 700;
        margin-bottom: .4rem;
      }

      p {
        font-size: .3rem;
        line-height: .48rem;
        margin-bottom: .68rem;
      }
    }
  }
</style>